<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      please click "Login to the App"
    </p>
    <a :href="'uniwebview://getUID?uid='+this.uid">
      <button>Login to the App</button>
    </a>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  data() {
    return {
      uid: '',
    }
  },

  mounted() {
    axios
      .get('https://learningtrail.eduhk.hk/Shibboleth.sso/Session')
      .then((res) => {
        this.uid = res.data.attributes[3].values[0]
        let formData = new FormData()
        formData.append('ssoUsername', this.uid)
        axios
          .post(
            'https://learningtrail.eduhk.hk/arlearn/login/sso_login.php/',
            formData
          )
          .then((res) => {
            console.log('post ok')
            console.log(res)
          })
          .catch((err) => {
            console.log(err.data)
          })
        window.location.href = 'uniwebview://getUID?uid=' + this.uid
      })
      .catch((err) => {
        console.log(err.data)
      })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
