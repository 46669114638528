<template>
  <!-- <img alt="Vue logo"
       src="./assets/logo.png"> -->
  <HelloWorld msg="Login successful" />
  <!-- <a :href="'uniwebview://getUID?uid='+this.uid">
    <button>Close Page</button>
  </a> -->
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  data() {
    return {
      uid: '',
    }
  },
  components: {
    HelloWorld,
  },

//   mounted() {
//     axios
//       .get('https://learningtrail.eduhk.hk/Shibboleth.sso/Session')
//       .then((res) => {
//         console.log(res)
//         console.log(res.data.attributes)
//         console.log(res.data.attributes[3])
//         console.log(res.data.attributes[3].values)
//         console.log(res.data.attributes[3].values[0])
//         console.log('111')
//         this.uid = res.data.attributes[3].values[0]
//       })
//       .catch((err) => {
//         console.log(err.data)
//       })
//   },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
